
import { defineComponent, PropType, ref, onBeforeMount, toRefs, watch } from 'vue'
import moment from 'moment'
import { ClassFeedbackSummary } from '@/models/course-feedbacks/summaries/classes/ClassFeedbackSummary'
import { CoursePerformanceColumnsWidth, CSSColors } from '@/models/enums'
import PerformanceCell from './PerformanceCell.vue'
import RatingByPersonTable from './RatingByPersonTable.vue'
import { EducationRouteNames } from '@/router/route-names'
import { Country } from '@/models/countries/classes/Country'
import { PersonRoles } from '@/models/person/enums'
import { CoursePerformanceFilters, ICoursePerformanceFilters } from '@/models/custom-filters/filters/CoursePerformanceFilters'
import { CoursePerformanceFiltersProps } from '@/models/custom-filters/filters/enums'
import { FeedbackSummary } from '@/models/course-feedbacks/summaries/classes/FeedbackSummary'
import { ClassAverageClassification } from '@/models/course-feedbacks/enums'
import CountrySelector from '@/components/courses/selectors/CountrySelector.vue'
import CourseTypeSelector from '@/components/courses/selectors/CourseTypeSelector.vue'
import CoursePlanSelector from '@/components/educationPlans/selectors/CoursePlanSelector.vue'
import { CoursePlan } from '@/models/academic-definitions/classes/CoursePlan'
import { ColumnFields } from '@/models/components/tables/enums'
import { Column } from '@/models/components/tables/Column'

export default defineComponent({
  props: {
    isNps: {
      type: Boolean,
      default: false
    },
    classSummaryFeedbacks: {
      type: Array as PropType<ClassFeedbackSummary[]>,
      default: () => []
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    allCoursePlans: {
      type: Array as PropType<CoursePlan[]>,
      required: true
    },
    recordsPerPage: {
      type: Number,
      default: 15
    },
    firstRecord: {
      type: Number,
      default: 0
    },
    totalRecords: {
      type: Number,
      detault: 0
    },
    customFilterActive: {
      type: Object as PropType<CoursePerformanceFilters>,
      require: false
    },
    allColumns: {
      type: Map as PropType<Map<ColumnFields, Column>>,
      require: true
    },
    selectedColumns: {
      type: Array as PropType<ColumnFields[]>,
      require: true
    }
  },
  components: {
    RatingByPersonTable,
    PerformanceCell,
    CountrySelector,
    CourseTypeSelector,
    CoursePlanSelector
  },
  emits: ['updateFilters'],
  setup (props, { emit }) {
    const { allColumns, selectedColumns, classSummaryFeedbacks: classSummaryFeedbacksRef, customFilterActive } = toRefs(props)
    const expandedRows = ref<ClassFeedbackSummary[]>([])
    const expandedRowsSet = ref(new Set<ClassFeedbackSummary>([]))
    watch(expandedRows, () => (expandedRowsSet.value = new Set([...expandedRows.value])))
    const filters = ref<ICoursePerformanceFilters>({
      countries: { value: null },
      startMonth: { value: null },
      courseType: { value: null },
      coursePlans: { value: null },
      columns: { value: null }
    } as ICoursePerformanceFilters)

    const matchFilterWithCustomFilterActive = () => {
      filters.value.countries.value = customFilterActive.value?.countries.value ?? null
      filters.value.startMonth.value = customFilterActive.value?.startMonth.value ? new Date(customFilterActive.value?.startMonth.value as string) : null
      filters.value.courseType.value = customFilterActive.value?.courseType.value ?? null
      filters.value.coursePlans.value = customFilterActive.value?.coursePlans.value ?? null
      filters.value.columns.value = customFilterActive.value?.columns?.value
        ? customFilterActive.value?.columns.value
        : allColumns.value
          ? Array.from(allColumns.value.keys())
          : null
    }

    onBeforeMount(matchFilterWithCustomFilterActive)
    watch(selectedColumns, matchFilterWithCustomFilterActive)

    const updateFilters = (f: ICoursePerformanceFilters): void => {
      emit('updateFilters', f)
    }

    const cleanFilters = (fp: CoursePerformanceFiltersProps[]): void => {
      fp.map(p => {
        filters.value[p].value = null
        return 0
      })
      emit('updateFilters', filters.value)
    }

    const teachers = (personSummary: Map<string, FeedbackSummary>) => {
      return [...personSummary.values()].filter((e) => e.role === 'teacher')
    }

    const showTag = (date: string) => {
      return moment(date, 'DD-MM-YYYY').isAfter(moment())
    }

    return {
      classSummaryFeedbacksRef,
      updateFilters,
      cleanFilters,
      CoursePerformanceFiltersProps,
      filters,
      expandedRows,
      CSSColors,
      expandedRowsSet,
      CoursePerformanceColumnsWidth,
      EducationRouteNames,
      Country,
      showTag,
      PersonRoles,
      FeedbackSummary,
      ClassAverageClassification,
      teachers,
      setBorderBottomCell: (
        data: ClassFeedbackSummary,
        averageClassification?: ClassAverageClassification
      ) => expandedRowsSet.value.has(data)
        ? typeof averageClassification !== 'undefined'
          ? averageClassification === ClassAverageClassification.Good
            ? 'border-bottom-green'
            : 'border-bottom-red'
          : 'border-bottom-transparent'
        : '',
      toggleExpand: (cfs: ClassFeedbackSummary): void => {
        if (expandedRowsSet.value.has(cfs)) {
          expandedRows.value = expandedRows.value.filter(r => r._id !== cfs._id)
        } else {
          expandedRows.value = [...expandedRows.value, cfs]
        }
      },
      ColumnFields
    }
  }
})
