import { watch, ref, Ref, onMounted, computed } from 'vue'

import { Country } from '@/models/countries/classes/Country'
import { DataTablePageEvent, DataTableSortEvent } from '@/models/interfaces/primevue'
import { ClassFeedbackSummary } from '@/models/course-feedbacks/summaries/classes/ClassFeedbackSummary'
import { CourseFeedbackSummaryAPI } from '@/api/feedbacks.api'
import { useToast } from 'primevue/usetoast'
import { ToastLife, ToastSeverities, ToastSummaries } from '@/models/components/toast/enums'
import { CourseAPI } from '@/api/course.api'
import { CourseStatus } from '@/models/course/enums'
import { CoursePerformanceFilters, ICoursePerformanceFilters } from '@/models/custom-filters/filters/CoursePerformanceFilters'
import { CourseType } from '@/models/enums'
import { CoursePlan } from '@/models/academic-definitions/classes/CoursePlan'
import { CoursePlanAPI } from '@/api/academic-definitions/course-plan.api'
import { TypeFilter, TableName } from '@/models/custom-filters/enum'
import { CustomFilter, FilterProps } from '@/models/custom-filters/classes/CustomFilter'
import { Observable } from 'rxjs'
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators'
import { useCustomFilters } from './useCustomFilters'
import { ColumnFields } from '@/models/components/tables/enums'
import { Column } from '@/models/components/tables/Column'

interface ClassFeedbackSummariesState {
  firstVal: Ref<number>
  loading: Ref<boolean>
  allCoursePlans: Ref<CoursePlan[]>
  filters: Ref<CoursePerformanceFilters>
  courses: Ref<ClassFeedbackSummary[]>
  totalRecords: Ref<number>
  coursesQtyNumber: Ref<number>
  searchTxt: Ref<string>
  sortFieldFilter: Ref<string|null>
  sortOrderFilter: Ref<number>
  recordsLimit: number
  performanceFilters: Ref<CustomFilter[]>
  performanceViews: Ref<CustomFilter[]>
  selectedCustomFilter: Ref<CustomFilter | null>,
  selectedCustomView: Ref<CustomFilter | null>,
  displayCreateFilter: Ref<{ show: boolean, type: string }>
  filterNameInput: Ref<string>
  isFiltered: Ref<boolean>
  toggleCreateFilter: (type: TypeFilter | null) => void
  removeActiveFilter: (type: TypeFilter) => void
  onPage: (e: DataTablePageEvent) => void
  onSort: (e: DataTableSortEvent) => void
  updateFilterListened: (f: FilterProps) => void
  saveFilters: (type: TypeFilter) => Promise<void>
  showConfirmDeleteFilter: (cf: CustomFilter) => void
  cleanAndUpdateFiltersBySelector: (f: CustomFilter) => void
  updateSearchTxt: (val: string) => void
  allColumns: Map<ColumnFields, Column>
  changeSelectedColumns: (keys: ColumnFields[]) => void
}

export const useClassFeedbackSummaries = (isNps: boolean): ClassFeedbackSummariesState => {
  // Active filter
  const filters = ref<CoursePerformanceFilters>(new CoursePerformanceFilters())
  const selectedCountries = computed<Country[]>(() => filters.value.countries.value as Country[])
  const selectedCoursePlans = computed<CoursePlan[]>(() => filters.value.coursePlans.value as CoursePlan[])
  const courseTypeFilter = computed<CourseType[]>(() => filters.value.courseType.value as CourseType[])
  const selectedMonth = computed<string>(() => filters.value.startMonth.value as string)
  const searchTxt = ref('')
  const updateSearchTxt = (val: string): void => {
    searchTxt.value = val
  }
  const searchTxt$ = new Observable<string>(subscriber => {
    watch(searchTxt, () => subscriber.next(searchTxt.value))
  })

  const allColumns: Map<ColumnFields, Column> = new Map([
    [ColumnFields.CourseNumber, { field: ColumnFields.CourseNumber, header: 'NºComisión' }],
    [ColumnFields.Name, { field: ColumnFields.Name, header: 'Curso' }],
    [ColumnFields.ClassNumber, { field: ColumnFields.ClassNumber, header: 'Clase' }],
    [ColumnFields.Start, { field: ColumnFields.Start, header: 'Inicio' }],
    [ColumnFields.End, { field: ColumnFields.End, header: 'Fin' }],
    [ColumnFields.ComissionTeacher, { field: ColumnFields.ComissionTeacher, header: 'Profesor' }],
    [ColumnFields.ComissionCorrectionTF, { field: ColumnFields.ComissionCorrectionTF, header: 'Correción del T Final' }],
    [ColumnFields.ComissionLimitDate, { field: ColumnFields.ComissionLimitDate, header: 'Fecha límite' }],
    [ColumnFields.ComissionGraduated, { field: ColumnFields.ComissionGraduated, header: 'Egresados' }],
    [ColumnFields.TeacherSummary, { field: ColumnFields.TeacherSummary, header: 'Rating Profesores' }],
    [ColumnFields.TutorSummary, { field: ColumnFields.TutorSummary, header: 'Rating tutores' }],
    [ColumnFields.ContentSummary, { field: ColumnFields.ContentSummary, header: 'Rating Contenido' }],
    [ColumnFields.CoordinatorSummary, { field: ColumnFields.CoordinatorSummary, header: 'Rating Coordinador' }],
    [ColumnFields.NpsSummary, { field: ColumnFields.NpsSummary, header: 'Rating NPS' }]
  ])

  const changeSelectedColumns = (keys: ColumnFields[]):void => {
    filters.value.columns = { value: keys }
  }

  // UI
  const toast = useToast()
  const loading = ref<boolean>(false)
  // Performance table config
  const courses = ref<ClassFeedbackSummary[]>([])
  const firstVal = ref(0)
  const recordsLimit = 15
  const sortFieldFilter = ref<string|null>(null)
  const sortOrderFilter = ref<number>(1)
  const totalRecords = ref<number>(0)
  const allCoursePlans = ref<CoursePlan[]>([])
  const coursesQtyNumber = ref<number>(0)

  const updateList = async () => {
    try {
      loading.value = true
      coursesQtyNumber.value = await CourseAPI.findQtyByStatus(isNps ? CourseStatus.Finished : CourseStatus.InProgress)
      const feedbackSummaryResponse = await CourseFeedbackSummaryAPI.findLastFromCourses({
        nps: isNps,
        offset: firstVal.value,
        limit: recordsLimit,
        search: encodeURIComponent(searchTxt.value.trim()),
        ...(selectedCountries.value) && { countries: selectedCountries.value.map(c => c.code).join(',') },
        ...(courseTypeFilter.value && { courseType: courseTypeFilter.value.join(',') }),
        ...(selectedMonth.value && { courseMonth: selectedMonth.value }),
        ...(selectedCoursePlans.value) && { coursePlans: selectedCoursePlans.value.map(c => c.information.name).join(',') },
        ...(isNps && { sortField: 'npsSummary.rating.value' }),
        ...(sortFieldFilter.value && sortOrderFilter.value && { sortField: sortFieldFilter.value, order: sortOrderFilter.value })
      })
      courses.value = feedbackSummaryResponse.data
      totalRecords.value = feedbackSummaryResponse.count
    } catch (error) {
      toast.add({
        summary: ToastSummaries.Error,
        detail: 'Ocurrió un error al cargar las comisiones',
        severity: ToastSeverities.Error,
        life: ToastLife.Default
      })
      console.error(error)
    } finally {
      loading.value = false
    }
  }

  const findAllCoursePlansToSelector = async () => {
    try {
      loading.value = true
      allCoursePlans.value = await CoursePlanAPI.findAll()
    } catch (e) {
      console.error(e)
    } finally {
      loading.value = false
    }
  }

  const {
    findAllCustomFilters,
    toggleCreateFilter,
    saveFilters,
    showConfirmDeleteFilter,
    removeActiveFilter,
    updateFilterListened,
    cleanAndUpdateFiltersBySelector,
    allCustomFilters,
    allCustomViews,
    isFiltered,
    filterNameInput,
    selectedCustomFilter,
    selectedCustomView,
    displayCreateFilter
  } = useCustomFilters({
    loading: loading,
    tableName: isNps ? TableName.CourseFinishedPerformanceView : TableName.CoursePerformanceView,
    filtersListened: filters,
    cleanFilter: new CoursePerformanceFilters({ columns: { value: (Array.from(allColumns.values()) as Column[]).map(c => c.field as ColumnFields) } } as ICoursePerformanceFilters) as unknown as FilterProps
  })

  onMounted(async () => {
    findAllCoursePlansToSelector()
    findAllCustomFilters()
    updateList()
  })

  watch(filters, () => {
    firstVal.value = 0
    updateList()
  })

  watch(searchTxt, () => {
    searchTxt$.pipe(
      debounceTime(800),
      distinctUntilChanged(),
      tap(() => (firstVal.value = 0))
    ).subscribe(updateList)
  })

  return {
    firstVal,
    removeActiveFilter,
    coursesQtyNumber,
    allCoursePlans,
    filters,
    loading,
    courses,
    searchTxt,
    totalRecords,
    sortFieldFilter,
    sortOrderFilter,
    filterNameInput,
    recordsLimit,
    performanceFilters: allCustomFilters,
    performanceViews: allCustomViews,
    selectedCustomFilter,
    selectedCustomView,
    displayCreateFilter,
    toggleCreateFilter,
    isFiltered,
    onPage: (e: DataTablePageEvent) => {
      firstVal.value = e.page * recordsLimit
      updateList()
    },
    onSort: (e: DataTableSortEvent) => {
      sortFieldFilter.value = e.sortField as string
      sortOrderFilter.value = sortOrderFilter.value * -1
      updateList()
    },
    updateFilterListened,
    saveFilters,
    showConfirmDeleteFilter,
    cleanAndUpdateFiltersBySelector,
    updateSearchTxt,
    allColumns,
    changeSelectedColumns
  }
}
