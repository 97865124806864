import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_spam = _resolveComponent("spam")!

  return (_openBlock(), _createBlock(_component_spam, null, {
    default: _withCtx(() => [
      _createVNode(_component_spam, {
        class: _normalizeClass([_ctx.labelClass])
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.label), 1)
        ]),
        _: 1
      }, 8, ["class"]),
      _createVNode(_component_spam, {
        class: _normalizeClass(['p-ml-2',_ctx.classForRating])
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.rating || '-'), 1)
        ]),
        _: 1
      }, 8, ["class"]),
      (!_ctx.hiddenChange)
        ? (_openBlock(), _createBlock(_component_spam, {
            key: 0,
            class: "p-text-right p-ml-2"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.change > 0 ? '+' : '') + _toDisplayString(_ctx.change || '-'), 1),
              (_ctx.change && _ctx.change !== 0)
                ? (_openBlock(), _createElementBlock("i", {
                    key: 0,
                    class: _normalizeClass(["pi", _ctx.change > 0 ? 'pi-caret-up' : 'pi-caret-down'])
                  }, null, 2))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}