
import { CourseType } from '@/models/enums'
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    disabled: {
      type: Boolean,
      required: true
    }
  },
  setup () {
    return {
      types: Object.values(CourseType).map(t => {
        switch (t) {
          case CourseType.B2b:
            return ({ name: 'B2B', value: t })
          case CourseType.B2c:
            return ({ name: 'B2C', value: t })
          case CourseType.Workshop:
            return ({ name: 'Workshop', value: t })
          case CourseType.Intensive:
            return ({ name: 'Intensivo', value: t })
          default:
            return ({ name: '', value: t })
        }
      })
    }
  }
})
