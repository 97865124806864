import { ColumnFields } from '@/models/components/tables/enums'
import { CoursePlan } from '../../academic-definitions/classes/CoursePlan'
import { Country } from '../../countries/classes/Country'
import { CourseType } from '../../enums'

export interface ICoursePerformanceFilters {
  countries: { value: Country[] | null }
  startMonth: { value: string | Date | null }
  courseType: { value: CourseType[] | null }
  coursePlans: { value: CoursePlan[] | null }
  columns: { value: ColumnFields[] | null }
  carrerino: { value: boolean | null | any }
  categories: { value: string[] | null }
  services: { value: string[] | null }

}

export class CoursePerformanceFilters {
  countries: { value: Country[] | null }
  startMonth: { value: string | Date | null }
  courseType: { value: CourseType[] | null }
  coursePlans: { value: CoursePlan[] | null }
  columns: { value: ColumnFields[] | null }
  carrerino: { value: boolean | null | any }
  categories: { value: string[] | null }
  services: { value: string[] | null }

  constructor (filters?: ICoursePerformanceFilters) {
    this.countries = filters?.countries || { value: null }
    this.startMonth = filters?.startMonth || { value: null }
    this.courseType = filters?.courseType || { value: null }
    this.coursePlans = filters?.coursePlans || { value: null }
    this.columns = filters?.columns || { value: null }
    this.carrerino = filters?.carrerino || { value: null }
    this.categories = filters?.categories || { value: null }
    this.services = filters?.services || { value: null }
  }
}
