import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-4548dae4")
const _hoisted_1 = { style: {"width":"45%"} }
const _hoisted_2 = {
  class: "rating-variation",
  style: {"width":"25%"}
}
const _hoisted_3 = { class: "p-d-flex p-jc-center p-ai-center" }
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("table", null, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.persons, ({ _id, name, lastname, rating }) => {
      return (_openBlock(), _createElementBlock("tr", { key: _id }, [
        _createElementVNode("td", _hoisted_1, [
          _createVNode(_component_router_link, {
            class: "person-name",
            to: {
            name: _ctx.EducationRouteNames.PERSON_PROFILE_GENERAL,
            params: { personId: _id }
          }
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(name) + " " + _toDisplayString(lastname), 1)
            ]),
            _: 2
          }, 1032, ["to"])
        ]),
        _createElementVNode("td", {
          class: _normalizeClass(["rating-value p-text-center", {
          'rating-ok': _ctx.personSummaryTotal.get(_id)?.rating.value >= 4.7,
          'rating-critical': !(_ctx.personSummaryTotal.get(_id)?.rating.value >= 4.7)
        }]),
          style: {"width":"15%"}
        }, _toDisplayString(_ctx.personSummaryTotal.get(_id)?.rating.value || '-'), 3),
        _createElementVNode("td", {
          class: _normalizeClass(["rating-value p-text-center", {
          'rating-ok': rating.value >= 4.7,
          'rating-critical': !(rating.value >= 4.7)
        }]),
          style: {"width":"15%"}
        }, _toDisplayString(rating.value || '-'), 3),
        _createElementVNode("td", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("span", null, _toDisplayString(rating.variation > 0 ? '+' : '') + _toDisplayString(rating.variation || '-'), 1),
            (rating.variation)
              ? (_openBlock(), _createElementBlock("i", {
                  key: 0,
                  class: _normalizeClass(["pi", rating.variation > 0 ? 'pi-caret-up' : 'pi-caret-down'])
                }, null, 2))
              : _createCommentVNode("", true)
          ])
        ])
      ]))
    }), 128))
  ]))
}