
import { defineComponent, computed } from 'vue'

export default defineComponent({
  name: 'PerformanceCell',
  props: {
    rating: Number,
    change: Number,
    hiddenChange: {
      type: Boolean,
      default: true
    },
    label: {
      type: String,
      default: 'General'
    },
    labelClass: String
  },
  setup (props) {
    const classForRating = computed(() =>
      (props.rating as number) >= 4.7
        ? 'rating-ok'
        : (props.rating as number) < 4.7
            ? 'rating-critical'
            : ''
    )
    return {
      classForRating
    }
  }
})
