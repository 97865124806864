
import { FeedbackSummary, FeedbackSummaryTotal } from '@/models/course-feedbacks/summaries/classes/FeedbackSummary'
import { EducationRouteNames } from '@/router/route-names'
import { defineComponent, PropType } from 'vue'

export default defineComponent({
  props: {
    persons: {
      type: Object as PropType<Array<FeedbackSummary>>,
      required: true
    },
    personSummaryTotal: {
      type: Object as PropType<Array<FeedbackSummaryTotal>>,
      required: true
    }
  },
  setup () {
    return {
      EducationRouteNames
    }
  }
})
