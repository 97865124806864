import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MultiSelect = _resolveComponent("MultiSelect")!

  return (_openBlock(), _createBlock(_component_MultiSelect, {
    panelClass: "overlay-gray",
    disabled: _ctx.disabled,
    options: _ctx.types,
    showToggleAll: false,
    optionLabel: "name",
    placeholder: "Tipo",
    display: "chip",
    optionValue: "value"
  }, null, 8, ["disabled", "options"]))
}